"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAP = void 0;
function validateAP(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    if (ieStr.length !== 9)
        return false;
    if (ieStr.substr(0, 2) !== '03')
        return false;
    var length = ieStr.length;
    var position = length - 1;
    var weight = length;
    var body = ieStr.substr(0, position);
    var bodyInt = +body;
    var p = 0;
    var d = 0;
    if (bodyInt >= 3000001 && bodyInt <= 3017000) {
        p = 5;
        d = 0;
    }
    else if (bodyInt >= 3017001 && bodyInt <= 3019022) {
        p = 9;
        d = 1;
    }
    var sum = p;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight--;
    });
    var dig = 11 - (sum % 11);
    if (dig === 10) {
        dig = 0;
    }
    if (dig === 11) {
        dig = d;
    }
    return dig === +ieStr.charAt(position);
}
exports.validateAP = validateAP;
