"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAL = void 0;
function validateAL(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    if (ieStr.length !== 9)
        return false;
    var weight = 9;
    var position = 8;
    var sum = 0;
    for (var i = 0; i < position; i++) {
        sum += +ieStr.charAt(i) * weight;
        weight--;
    }
    var product = sum * 10;
    var digit = product - Math.floor(product / 11) * 11;
    if (digit >= 10) {
        digit = 0;
    }
    return digit === +ieStr.charAt(position);
}
exports.validateAL = validateAL;
