"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAM = void 0;
function validateAM(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    if (ieStr.length !== 9)
        return false;
    var length = ieStr.length;
    var position = length - 1;
    var weight = length;
    var body = ieStr.substr(0, position);
    var sum = 0;
    var dig = 0;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight--;
    });
    if (sum < 11) {
        dig = 11 - sum;
    }
    else {
        var rest = sum % 11;
        dig = 11 - rest;
        if (dig >= 10) {
            dig = 0;
        }
    }
    return dig === +ieStr.charAt(position);
}
exports.validateAM = validateAM;
