"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAC = void 0;
function calcDigit(body) {
    var weight = body.length - 7;
    var sum = 0;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight--;
        if (weight === 1) {
            weight = 9;
        }
    });
    var mod = 11;
    var rest = sum % mod;
    var dig = mod - rest;
    if (dig >= 10) {
        dig = 0;
    }
    return dig;
}
function validateAC(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    if (ieStr.length !== 13)
        return false;
    if (ieStr.substr(0, 2) !== '01')
        return false;
    var length = ieStr.length;
    var body = ieStr.substr(0, length - 2);
    var fDig = calcDigit(body);
    var sDig = calcDigit(body + fDig.toString());
    var pos2dig = ieStr.length - 1;
    var pos1dig = ieStr.length - 2;
    return +ieStr[pos1dig] === fDig && +ieStr[pos2dig] === sDig;
}
exports.validateAC = validateAC;
