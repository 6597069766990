"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateRN = void 0;
function validateRN(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    var length = ieStr.length;
    if (![9, 10].includes(length))
        return false;
    if (ieStr.substr(0, 2) !== '20')
        return false;
    var position = length - 1;
    var weight = length;
    var body = ieStr.substr(0, position);
    var sum = 0;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight--;
    });
    var rest = sum % 11;
    var dig = 11 - rest;
    if (dig >= 10) {
        dig = 0;
    }
    return dig === +ieStr.charAt(position);
}
exports.validateRN = validateRN;
