"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateMT = void 0;
function validateMT(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    var length = ieStr.length;
    if (length !== 11)
        return false;
    var position = length - 1;
    var weight = 3;
    var body = ieStr.substr(0, position);
    var sum = 0;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight--;
        if (weight === 1) {
            weight = 9;
        }
    });
    var rest = sum % 11;
    var dig = 11 - rest;
    if (dig >= 10) {
        dig = 0;
    }
    return dig === +ieStr.charAt(position);
}
exports.validateMT = validateMT;
