"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePE = void 0;
function calcDigit(body) {
    var weight = body.length + 1;
    var sum = 0;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight--;
    });
    var mod = 11;
    var rest = sum % mod;
    var dig = mod - rest;
    if (dig >= 10) {
        dig = 0;
    }
    return dig;
}
function validatePE(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    var length = ieStr.length;
    if (length !== 9)
        return false;
    var body = ie.substr(0, length - 2);
    var firstDig = calcDigit(body);
    var secondDig = calcDigit(body + firstDig);
    var posSecondDig = length - 1;
    var posFirstDig = length - 2;
    var ieAtFirstPos = +ieStr.charAt(posFirstDig);
    var ieAtSecondPos = +ieStr.charAt(posSecondDig);
    return ieAtFirstPos === firstDig && ieAtSecondPos === secondDig;
}
exports.validatePE = validatePE;
