"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePhone = void 0;
function validatePhone(phone) {
    var clearPhone = phone.replace(/\D/g, '');
    var sameCharacterRegexp = /^(.)\1*$/;
    if (!(clearPhone.length >= 8 && clearPhone.length <= 11)) {
        return false;
    }
    if (sameCharacterRegexp.test(clearPhone)) {
        return false;
    }
    if (clearPhone.length > 9 && [0, 1].indexOf(clearPhone.indexOf('0')) !== -1) {
        return false;
    }
    var shortNumber = clearPhone.length > 9 ? clearPhone.substring(2) : clearPhone;
    if (shortNumber.length === 8) {
        return [2, 3, 4, 5, 7].indexOf(+shortNumber[0]) !== -1;
    }
    return shortNumber[0] === '9';
}
exports.validatePhone = validatePhone;
