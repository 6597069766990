"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateSP = void 0;
function calcFirstDigit(ie) {
    var body = ie.substr(0, 8);
    var weight = [1, 3, 4, 5, 6, 7, 8, 10];
    var sum = 0;
    body.split('').forEach(function (digit, index) {
        sum += +digit * weight[index];
    });
    var dig = sum % 11;
    var digit = dig.toString();
    return +digit.substr(digit.length - 1, 1);
}
function calcSecondDigit(ie) {
    var body = ie.substr(0, 11);
    var weight = 3;
    var sum = 0;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight--;
        if (weight === 1) {
            weight = 10;
        }
    });
    var dig = sum % 11;
    var digit = dig.toString();
    return +digit.substr(digit.length - 1, 1);
}
function validateSP(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    if (ieStr.length !== 12)
        return false;
    var length = ieStr.length;
    var positionFirstDigit = length - 4;
    var positionSecondDigit = length - 1;
    var firstDigit = calcFirstDigit(ieStr);
    var secondDigit = calcSecondDigit(ieStr);
    var ieAtFirstPos = +ieStr.charAt(positionFirstDigit);
    var ieAtSecondPos = +ieStr.charAt(positionSecondDigit);
    return firstDigit === ieAtFirstPos && secondDigit === ieAtSecondPos;
}
exports.validateSP = validateSP;
