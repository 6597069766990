"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePIS = void 0;
function generateChecksum(base, weight) {
    var digits = String(base).replace(/[^\d]/g, '');
    return digits
        .split('')
        .reduce(function (acc, digit, i) { return acc + +digit * weight[i]; }, 0);
}
var weights = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
var reservedNumbers = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
];
function validatePIS(pis) {
    var pisStr = String(pis).replace(/\D/g, '');
    var numeric = pisStr.replace(/[ ().,*-]/g, '');
    if (!(pisStr.length === 11) ||
        reservedNumbers.indexOf(pisStr) >= 0 ||
        !/^[0-9]+$/.test(pisStr))
        return false;
    var weightedChecksum = generateChecksum(numeric.substr(0, numeric.length - 1), weights);
    var verifyingDigit = +numeric.charAt(numeric.length - 1);
    var calculatedDigit = 11 - (weightedChecksum % 11);
    return (calculatedDigit === verifyingDigit ||
        (calculatedDigit === 10 && verifyingDigit === 0) ||
        (calculatedDigit === 11 && verifyingDigit === 0));
}
exports.validatePIS = validatePIS;
