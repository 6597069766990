"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useValidationsBR = void 0;
var validatePhone_1 = require("./validatePhone");
var validateEmail_1 = require("./validateEmail");
var validateCep_1 = require("./validateCep");
var validateUF_1 = require("./validateUF");
var validateCNPJ_1 = require("./validateCNPJ");
var validateCPF_1 = require("./validateCPF");
var validatePIS_1 = require("./validatePIS");
var validateCNH_1 = require("./validateCNH");
var useValidationsBR = function (type, value) {
    var result = false;
    switch (type) {
        case 'cnpj':
            result = (0, validateCNPJ_1.validateCNPJ)(value);
            break;
        case 'cpf':
            result = (0, validateCPF_1.validateCPF)(value);
            break;
        case 'cep':
            result = (0, validateCep_1.validateCep)(value);
            break;
        case 'email':
            result = (0, validateEmail_1.validateEmail)(value);
            break;
        case 'pis':
            result = (0, validatePIS_1.validatePIS)(value);
            break;
        case 'cnh':
            result = (0, validateCNH_1.validateCNH)(value);
            break;
        case 'phone':
            result = (0, validatePhone_1.validatePhone)(value);
            break;
        case 'uf':
            result = (0, validateUF_1.validateUF)(value);
            break;
        default:
    }
    return result;
};
exports.useValidationsBR = useValidationsBR;
