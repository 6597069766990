"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateDF = void 0;
function calcDigit(body) {
    var weight = body.length - 7;
    var sum = 0;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight--;
        if (weight === 1) {
            weight = 9;
        }
    });
    var mod = 11;
    var rest = sum % mod;
    var dig = mod - rest;
    if (dig >= 10) {
        dig = 0;
    }
    return dig;
}
function validateDF(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    var length = ieStr.length;
    if (length !== 13) {
        return false;
    }
    if (ieStr.substr(0, 2) !== '07' && ieStr.substr(0, 2) !== '08') {
        return false;
    }
    var body = ieStr.substr(0, length - 2);
    var firstDig = calcDigit(body);
    var secondDig = calcDigit(body + firstDig);
    var posSecondDig = length - 1;
    var posFirstDig = length - 2;
    var ieAtFirstPos = +ieStr.charAt(posFirstDig);
    var ieAtSecondPos = +ieStr.charAt(posSecondDig);
    return ieAtFirstPos === firstDig && ieAtSecondPos === secondDig;
}
exports.validateDF = validateDF;
