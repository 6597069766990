"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateRR = void 0;
function validateRR(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    var length = ieStr.length;
    if (length !== 9)
        return false;
    if (ieStr.substr(0, 2) !== '24')
        return false;
    var position = length - 1;
    var weight = 1;
    var body = ieStr.substr(0, position);
    var sum = 0;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight++;
    });
    var dig = sum % 9;
    return dig === +ieStr.charAt(position);
}
exports.validateRR = validateRR;
