"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateTO = void 0;
function calcDigit(ie) {
    var length = ie.length;
    var position = length - 1;
    var weight = length;
    var body = ie.substr(0, position);
    var sum = 0;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight--;
    });
    var rest = sum % 11;
    var dig = 11 - rest;
    if (dig >= 10) {
        dig = 0;
    }
    return dig === +ie.charAt(position);
}
function oldStartsWith(ie) {
    var beginWith = ['01', '02', '03', '99'];
    var begin = ie.substr(2, 2);
    return beginWith.indexOf(begin) >= 0;
}
function calcOld(ie) {
    return calcDigit(ie);
}
function checkOld(ie) {
    var body = ie.slice(0, 2) + ie.slice(4);
    return oldStartsWith(ie) && calcOld(body);
}
function calcNew(ie) {
    var length = ie.length;
    var position = length - 1;
    var weight = 9;
    var body = ie.substr(0, position);
    var sum = 0;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight--;
    });
    var rest = sum % 11;
    var dig = 11 - rest;
    if (rest < 2) {
        dig = 0;
    }
    return dig === +ie.charAt(position);
}
function checkNew(ie) {
    return calcNew(ie);
}
function validateTO(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    if (![9, 11].includes(ieStr.length))
        return false;
    var oldCheck = checkOld(ieStr);
    var newCheck = checkNew(ieStr);
    return oldCheck || newCheck;
}
exports.validateTO = validateTO;
