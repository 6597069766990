"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateMA = void 0;
var ce_1 = require("./ce");
function validateMA(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    if (ieStr.substr(0, 2) !== '12')
        return false;
    return (0, ce_1.validateCE)(ieStr);
}
exports.validateMA = validateMA;
