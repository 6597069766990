"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateBA = void 0;
function getModule(ie) {
    var pos = 0;
    if (ie.length === 9) {
        pos = 1;
    }
    var charAt = +ie.substr(pos, 1);
    var arr = [0, 1, 2, 3, 4, 5, 8];
    if (arr.indexOf(charAt) >= 0) {
        return 10;
    }
    return 11;
}
function calcDigit(body, mod) {
    var weight = body.length + 1;
    var sum = 0;
    body.split('').forEach(function (digit) {
        sum += +digit * weight;
        weight--;
    });
    var rest = sum % mod;
    var dig = mod - rest;
    if (dig >= 10) {
        dig = 0;
    }
    return dig;
}
function validateBA(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    var length = ieStr.length;
    if (![8, 9].includes(length))
        return false;
    var body = ieStr.substr(0, length - 2);
    var mod = getModule(ieStr);
    var secondDig = calcDigit(body, mod);
    var firstDig = calcDigit(body + secondDig, mod);
    var posSecondDig = length - 1;
    var posFirstDig = length - 2;
    var ieAtFirstPos = +ieStr.charAt(posFirstDig);
    var ieAtSecondPos = +ieStr.charAt(posSecondDig);
    return ieAtFirstPos === firstDig && ieAtSecondPos === secondDig;
}
exports.validateBA = validateBA;
