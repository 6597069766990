"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useValidationsBR = exports.validateCNH = exports.validatePIS = exports.validateIE = exports.validateCPF = exports.validateCNPJ = exports.validateUF = exports.validateCep = exports.validateEmail = exports.validatePhone = void 0;
var validatePhone_1 = require("./validations/validatePhone");
Object.defineProperty(exports, "validatePhone", { enumerable: true, get: function () { return validatePhone_1.validatePhone; } });
var validateEmail_1 = require("./validations/validateEmail");
Object.defineProperty(exports, "validateEmail", { enumerable: true, get: function () { return validateEmail_1.validateEmail; } });
var validateCep_1 = require("./validations/validateCep");
Object.defineProperty(exports, "validateCep", { enumerable: true, get: function () { return validateCep_1.validateCep; } });
var validateUF_1 = require("./validations/validateUF");
Object.defineProperty(exports, "validateUF", { enumerable: true, get: function () { return validateUF_1.validateUF; } });
var validateCNPJ_1 = require("./validations/validateCNPJ");
Object.defineProperty(exports, "validateCNPJ", { enumerable: true, get: function () { return validateCNPJ_1.validateCNPJ; } });
var validateCPF_1 = require("./validations/validateCPF");
Object.defineProperty(exports, "validateCPF", { enumerable: true, get: function () { return validateCPF_1.validateCPF; } });
var ie_1 = require("./validations/ie");
Object.defineProperty(exports, "validateIE", { enumerable: true, get: function () { return ie_1.validateIE; } });
var validatePIS_1 = require("./validations/validatePIS");
Object.defineProperty(exports, "validatePIS", { enumerable: true, get: function () { return validatePIS_1.validatePIS; } });
var validateCNH_1 = require("./validations/validateCNH");
Object.defineProperty(exports, "validateCNH", { enumerable: true, get: function () { return validateCNH_1.validateCNH; } });
var usevalidationsbr_1 = require("./validations/usevalidationsbr");
Object.defineProperty(exports, "useValidationsBR", { enumerable: true, get: function () { return usevalidationsbr_1.useValidationsBR; } });
