"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateMG = void 0;
function calcFirstDigit(ie) {
    var body = ie.slice(0, 3) + 0 + ie.slice(3);
    var concat = '';
    body.split('').forEach(function (item, index) {
        var weight = (index + 3) % 2 === 0 ? 2 : 1;
        concat += +item * weight;
    });
    var sum = 0;
    concat.split('').forEach(function (item) {
        sum += +item;
    });
    var sumString = sum.toString();
    var length = sumString.length;
    var lastChar = sumString.substr(length - 1, 1);
    var lastCharInt = +lastChar;
    return lastCharInt === 0 ? 0 : 10 - lastCharInt;
}
function calcSecondDigit(body) {
    var weight = 3;
    var sum = 0;
    body.split('').forEach(function (item) {
        sum += +item * weight;
        weight--;
        if (weight === 1) {
            weight = 11;
        }
    });
    var rest = sum % 11;
    var digit = 11 - rest;
    if (digit >= 10) {
        digit = 0;
    }
    return digit;
}
function validateMG(ie) {
    var ieStr = String(ie).replace(/\D/g, '');
    var length = ieStr.length;
    if (length !== 13)
        return false;
    var posFirstDigit = length - 2;
    var posSecondDigit = length - 1;
    var body = ieStr.substring(0, 11);
    var firstDigit = calcFirstDigit(body);
    var secondDigit = calcSecondDigit(body + firstDigit);
    var digitAtFirstDigit = +ieStr.charAt(posFirstDigit);
    var digitAtSecondDigit = +ieStr.charAt(posSecondDigit);
    return firstDigit === digitAtFirstDigit && secondDigit === digitAtSecondDigit;
}
exports.validateMG = validateMG;
