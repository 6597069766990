"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateCNH = void 0;
/**
 * Valida a CNH. A entrada pode ser com ou sem máscaras.
 * O tamanho deve ser respeitado como em '000000000-00' ou '00000000000'.
 * @param value
 */
function validateCNH(value) {
    var clearValue = String(value).replace(/\D/g, '');
    if (!clearValue ||
        clearValue.replace(new RegExp(clearValue[0], 'g'), '').trim().length === 0) {
        return false;
    }
    var val = 0;
    for (var i = 0, j = 9; i < 9; ++i, --j) {
        val += Number(value.charAt(i)) * j;
    }
    var dsc = 0;
    var vl1 = val % 11;
    if (vl1 >= 10) {
        vl1 = 0;
        dsc = 2;
    }
    val = 0;
    for (var i = 0, j = 1; i < 9; ++i, ++j) {
        val += Number(value.charAt(i)) * j;
    }
    var x = val % 11;
    var vl2 = x >= 10 ? 0 : x - dsc;
    return "".concat(vl1).concat(vl2) === value.substring(value.length - 2);
}
exports.validateCNH = validateCNH;
